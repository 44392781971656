/**
 * i18n.ts
 *
 * This file contains utility functions and configurations for internationalization (i18n) in the application.
 * It includes functions for managing countries, languages, locales,
 * and various helper methods for working with internationalization-related data.
 *
 * Key features:
 * - Country and language management
 * - Locale generation and manipulation
 * - URL locale updating
 * - Language setting and storage
 * - Request configuration for Next.js Internationalization
 *
 *
 *
 * Example environment variables for supported countries and default country:
 * Note: These should be replaced with actual environment variables in production
 *
 * - NEXT_PUBLIC_APP_COUNTRIES: Array of supported countries with their details
 * - NEXT_PUBLIC_APP_COUNTRIES=[{"id":82,"iso2":"DE","languages":["en"],"primaryLanguage":"en"},{"id":225,"iso2":"TR","languages":["tr","en"],"primaryLanguage":"tr"}]
 *
 * - NEXT_PUBLIC_APP_DEFAULT_COUNTRY: Default country configuration
 * - NEXT_PUBLIC_APP_DEFAULT_COUNTRY={"id":225,"iso2":"TR","languages":["tr","en"],"primaryLanguage":"tr"}
 */

import {getRequestConfig} from 'next-intl/server'

type Country = {
  id: number
  iso2: string
  languages: string[]
  primaryLanguage: string
}

export const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

/**
 * Object representing countries.
 * @type {Object}
 */
export const getCountries = (): Country[] => {
  return JSON.parse(process.env.NEXT_PUBLIC_APP_COUNTRIES as string)
}

/**
 * Retrieves the default country configuration.
 * @returns {Country} The default country object.
 */
export const getDefaultCountry = (): Country => {
  return JSON.parse(process.env.NEXT_PUBLIC_APP_DEFAULT_COUNTRY as string)
}

/**
 * Retrieves all unique languages available across all countries.
 *
 * This function gets all countries, extracts their languages,
 * flattens the array, and returns a unique set of languages.
 *
 * @returns {string[]} An array of unique language codes.
 */
export const getAllAvailableLanguagesInAllCountries = (): string[] => {
  return Array.from(
    new Set(
      Object.values(getCountries())
        .map((c) => c.languages)
        .flat()
    )
  )
}

/**
 * Retrieves the languages associated with a specific country.
 *
 * @param {string} countryISO2 - The ISO 2-letter country code.
 * @returns {string[]} An array of language codes for the specified country.
 */
export const getCountryLanguages = (countryISO2: string): string[] => {
  const country = getCountries().find((country) => country.iso2 === countryISO2)
  return country!.languages
}

/**
 * Retrieves a country object based on its ISO 2-letter country code.
 *
 * @param {string} countryISO2 - The ISO 2-letter country code to search for.
 * @returns {Country | undefined} The country object if found, undefined otherwise.
 */
export const getCountryByCountryISO2 = (countryISO2: string): Country | undefined => {
  return getCountries().find((c) => c.iso2 === countryISO2)
}

/**
 * Retrieves a country object based on its country ID.
 *
 * @param {string} countryId - The ID of the country to search for.
 * @returns {Country} The country object matching the given ID.
 */
export const getCountryByCountryId = (countryId: string): Country => {
  return getCountries().find((c) => c.id.toString() === countryId.toString())!
}

/**
 * Retrieves the primary language of a country based on its ISO 2-letter country code.
 *
 * @param {string} countryISO2 - The ISO 2-letter country code of the country.
 * @returns {string} The primary language code of the specified country.
 */
export const getCountryPrimaryLanguage = (countryISO2: string): string => {
  return getCountryByCountryISO2(countryISO2)!.primaryLanguage
}

/**
 * Retrieves the primary language of the default country.
 *
 * @returns {string} The primary language code of the default country.
 */
export const getDefaultCountryPrimaryLanguage = (): string => {
  return getDefaultCountry().primaryLanguage
}

/**
 * Generates an array of all possible locale combinations.
 *
 * This function creates locale strings by combining each available language
 * with each country's ISO 2-letter code. The resulting array contains
 * strings in the format "language-countryCodeISO2" for all possible combinations.
 *
 * @returns {string[]} An array of locale strings.
 */
export const locales = (): string[] => {
  const _locales: string[] = []

  const allLanguages = getAllAvailableLanguagesInAllCountries()

  allLanguages.forEach((language) => {
    Object.values(getCountries()).forEach((country) => {
      _locales.push(`${language}-${country.iso2}`)
    })
  })

  return _locales
}

/**
 * Defines the default locale string for the application.
 *
 * This constant combines the primary language of the default country
 * with the ISO 2-letter code of the default country, formatted as
 * "language-countryCodeISO2".
 *
 * @type {string}
 */
export const defaultLocale = `${getDefaultCountryPrimaryLanguage()}-${getDefaultCountry().iso2}`

/**
 * Extracts the country ID from a given locale string.
 *
 * @param {string} locale - The locale string in the format "language-countryCodeISO2".
 * @returns {number} The ID of the country corresponding to the country code in the locale.
 */
export const getCountryIdFromLocale = (locale: string): number => {
  return getCountryByCountryISO2(locale.split('-')[1])!.id
}

/**
 * Extracts the language code from a given locale string.
 *
 * @param {string} locale - The locale string in the format "language-countryCodeISO2".
 * @returns {string} The language code extracted from the locale string.
 */
export const getLanguageCodeFromLocale = (locale: string): string => {
  return locale.split('-')[0]
}

/**
 * Generates a locale string from a language code and country ID.
 *
 * @param {string} languageCode - The language code.
 * @param {string | number} countryId - The ID of the country.
 * @returns {string} A locale string in the format "languageCode-countryCodeISO2".
 */
export const generateLocaleString = (languageCode: string, countryId: string | number): string => {
  return `${languageCode}-${getCountryByCountryId(countryId.toString())?.iso2}`
}

/**
 * Checks if a given language code exists in a specified country.
 *
 * @param {string} languageCode - The language code to check.
 * @param {string} countryId - The ID of the country to check against.
 * @returns {boolean} True if the language code exists in the country, false otherwise.
 */
export const existLanguageCodeInCountry = (languageCode: string, countryId: string): boolean => {
  const country = getCountryByCountryId(countryId)
  if (country) return country.languages.includes(languageCode)

  return false
}

/**
 * Checks if a country with the given ID exists.
 *
 * @param {string} countryId - The ID of the country to check.
 * @returns {boolean} True if a country with the given ID exists, false otherwise.
 */
export const existCountryWithID = (countryId: string): boolean => {
  return getCountries().findIndex((c) => c.id.toString() === countryId) > -1
}

/**
 * Checks if a country with the given ISO2 code exists.
 *
 * @param {string} countryISO2 - The ISO2 code of the country to check.
 * @returns {boolean} True if a country with the given ISO2 code exists, false otherwise.
 */
export const existCountryWithISO2 = (countryISO2: string): boolean => {
  return getCountries().some((country: any) => country.iso2 === countryISO2)
}

/**
 * Updates the locale in the given URL with the target language and country.
 *
 * @param {string} url - The original URL to update.
 * @param {string} targetLanguage - The target language code.
 * @param {number} targetCountryId - The ID of the target country.
 * @returns {string} The updated URL with the new locale.
 */
export const updateURLLocale = (url: string, targetLanguage: string, targetCountryId: number) => {
  const localeRegex = /^\/?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)(\/|$)/

  return url.replace(
    localeRegex,
    `/${targetLanguage}-${getCountryByCountryId(targetCountryId.toString()).iso2}$2`
  )
}

/**
 * Sets the language for the application and optionally reloads the page.
 *
 * @param {string} language - The language code to set.
 * @param {string} countryId - The ID of the country associated with the language.
 * @param {boolean} [reload] - Whether to reload the page after setting the language.
 */
export const setLanguage = (language: string, countryId: string, reload?: boolean) => {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: language}))

  if (reload) {
    window.location.pathname = `/v2/${language}-${getCountryByCountryId(countryId).iso2}`
  }
}

/**
 * Configures the request for internationalization.
 *
 * @param {Function} getRequestConfig - The function to get the request configuration.
 * @param {Object} context - The context object containing locale information.
 * @returns {Promise<Object>} A promise that resolves to an object containing the messages for the specified locale.
 */
export default getRequestConfig(async (context) => {
  return {
    messages: (await import(`./i18n/messages/${getLanguageCodeFromLocale(context.locale)}.json`))
      .default,
  }
})
